import React from 'react'
import {Helmet} from "react-helmet"
import {Button} from 'react-bootstrap'
import {Link} from 'gatsby'
import Navbar from '../components/oneTimeComponents/NavigationBar'
import Collegamenti from '../components/basicComponents/Collegamento'
import SPIDContainer from '../components/SPIDComponents/SPIDContainer'
import './AccessoSistemaWF.css'

export default function AccessWorkflowSystem(){
    const stileButton ={
        backgroundColor:"white",
        color:"black",
        border:"1px solid black"
    }
    return(
        <div className="pageContainer">
        <Helmet>
            <title>Access Workflow System</title>
        </Helmet>
        <Navbar page="EN"  sezione="1"/>
        <br/>
        <div>
            <h4>
                <b>Access to Research Workflow 2.0 and Financial Reporting</b>
            </h4>
        </div>
        <SPIDContainer page="EN" />
        <div className="Collegamenti" style={{marginTop:"5%"}}>
            <a href="http://wfds.cbim.it/bandoDS/#">
                <strong>Call for Scientific Directors - Project Submission</strong>
            </a>
            <br/>
            <a href= "https://dgr.cbim.it/">
                <strong>Call for Scientific Directors - Reviewers Section</strong>
            </a>

        <Link to="/EN">
        <button className="ButtonHome" style={stileButton}>
            HOME
        </button>
        </Link>

        </div>
        </div>
    )
}